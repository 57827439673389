import React, {useEffect, useState} from 'react';
import useTranslations from "../../utils/useTranslations"
import ComparisonPanels from "../../components/comparisonPanels"
import LocalizedLink from "../../components/localizedLink"
import backgrounds from "../../components/backgrounds"
import Button from "../../components/button"

const UtorrentWebProOffer = ({ pageContext: {text, pageName, locale}, location }) => {
const t = useTranslations(text)

const [isMac, setIsMac] = useState(false);

useEffect(() => {
	setIsMac(window.gatsbyIsMac)
	},[]
)

  return (
  	<>
	  	<div className="background-noise-dark py-5" style={backgrounds.darkBg2}>
	  		<div className="container py-lg-5 text-white text-center">
	  			<h1 className="text-size-36">
		  			Up to 90% of torrents are unsafe!
	  			</h1>
	  			<p className="text-size-16 mt-0">
	  				Source: Adaware Malware Labs torrent analysis October 2020
	  			</p>
	  			<h2 className="font-weight-normal text-size-24 mt-4">
	  				<b>New μTorrent Web Pro</b>
	  			</h2>
	  			<p className="text-size-16 mt-0">
	  				Filters Malware to stay safe
	  			</p>
                <Button href="/webpro-offer/#cta-target" id="webpro-offer-cta-learnmore" className="btn-primary text-spacing-1 mt-4 button-filled text-spacing-1">Learn More</Button>

	  		</div>
	  	</div>
  		<div className="container-fluid py-lg-5 background-noise-light px-0" id="cta-target">
  			<div className="container px-lg-0 d-flex flex-column flex-lg-row pt-5">
  				<div className="col-lg-6 px-0 pr-lg-3">
		  			<p className="text-size-24 font-weight-bold text-primary mb-4">
		  				μTorrent Web Pro+VPN
		  			</p>
		  			<p className="webpro-offer-text">
		  				Get all Pro features, as well as a 1-year subscription to CyberGhost VPN. Includes built-in threat protection to keep your torrent downloads clean and a utility displays helpful info when you search for torrents: file size, resolution, and number of seeds and leeches. Get VPN on up to 5 devices to protect your online identity and safeguard your personal data from hackers.
		  			</p>
  				</div>
  				<div className="col-lg-6 px-0 pl-lg-3">
		  			<p className="text-size-24 font-weight-bold text-primary mb-4 mt-4 mt-lg-0">
		  				μTorrent Web Pro
		  			</p>
		  			<p className="webpro-offer-text">
		  				Try our most popular premium web torrent client and keep your PC safe. A safe torrent scanner automatically checks torrents for threats before you download, as well as displays helpful info while you search to find the right torrent: file size, resolution, and number of seeds and leeches. Pro includes access to premium customer support.
		  			</p>
  				</div>
  			</div>
  		</div>
	  	<div className="container-fluid py-5 background-noise-light panel-bg-2">
			<ComparisonPanels pageContext={{ "text":text, "pageName":pageName, isMac }}>
			</ComparisonPanels>
	  	</div>
	</>
  )
}

export default UtorrentWebProOffer